import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CalsiumDateFormatterPipe } from 'src/app/shared/calsium-date.pipe';

@Component({
  selector: 'cal-confirmation-advance-dialog',
  templateUrl: './confirmation-advance-dialog.component.html',
  styleUrl: './confirmation-advance-dialog.component.css'
})
export class ConfirmationAdvanceDialogComponent {

  reasonOfRemoval: string = '';
  checkedDate: any = {};

  constructor(
    public dialogRef: MatDialogRef<ConfirmationAdvanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  save() {
    this.dialogRef.close(
      {
        reasonOfRemoval: this.reasonOfRemoval,
        checkedDate: this.checkedDate
      }
    )
  }

  closeModel() {
    this.dialogRef.close()
  }

  isOkDisabled() {
    return (
      !Object.keys(this.checkedDate)?.length || !this.reasonOfRemoval?.length
    )
  }

  checkedDateChange(event: MatCheckboxChange, date: string) {
    if (event.source.checked === false) {
      this.checkedDate = {}
    }
    else {
      this.checkedDate = date;
    }
  }

  validationPatternChange(event: any) {
    let reason = event.target.value.trim();
    this.reasonOfRemoval = reason;
  }
}
