import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { RemoveInvoiceDateForProductIndividual } from "src/state/removed-invoice-date/state-models/removed-invoicedate-data";
import { lastValueFrom, map } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class RemovedInvoicedateListApiService {
  constructor(
    private apollo: Apollo
  ) { }

  getRemovedInvoicedatesList(variables?): Promise<any> {
    const result$ = this.apollo
      .query<RemoveInvoiceDateForProductIndividual[]>({
        variables,
        fetchPolicy: "no-cache",
        query: gql`
            query removedInvoicedatesListQuery($paging: Paging, $order:String, $filter:String) 
            {
              removedInvoiceDatesList 
              {
                removedInvoiceDates(order: $order, filter: $filter, paging: $paging) 
                {
                  list {
                    chassisNumber
                    serialNumber
                    productIndividualNumber
                    invoiceDate
                    country
                    updatedBy
                    reasonOfRemoval
                  }    
                  totalCount     
                }
              }
            }`
      })
      .pipe<RemoveInvoiceDateForProductIndividual[]>(

        map((result: any) => {
          return result?.data?.removedInvoiceDatesList?.removedInvoiceDates;
        })

      )
    return lastValueFrom(result$);
  }

  getInvoiceDates(variables?): Promise<RemoveInvoiceDateForProductIndividual[]> {
    const result$ =this.apollo
        .query<RemoveInvoiceDateForProductIndividual[]>({
            variables,
            fetchPolicy: "no-cache",
            query: gql`
                query invoiceIndividualQuery($productIndividualId: Int) {
                    removedInvoiceDatesList {
                        invoiceIndividual(productIndividualId: $productIndividualId) {
                            detailsId
                            invoiceDate
                        }
                    }
                }
            `,
        })
        .pipe<RemoveInvoiceDateForProductIndividual[]>(
          
            map((result: any) => {
                return result?.data?.removedInvoiceDatesList?.invoiceIndividual;
                }
          ));

          return  lastValueFrom(result$);
  }

 async removeInvoiceDate(payload: RemoveInvoiceDateForProductIndividual): Promise<any> {
    const mutation = `
    mutation removeInvoiceDate($payload: RemoveInvoiceDateInput!) {
      invoiceIndividual {
        removeInvoiceDate (payload: $payload)
      }
    }`
    return this.apollo
      .mutate({
        mutation: gql(mutation),
        variables: { payload },
      })
      .toPromise();
  }
}