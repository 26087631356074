import { Injectable, NgModule, OnDestroy } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { StoreModule, Store, MetaReducer } from '@ngrx/store';

import { reducers } from './app.reducers';
import { CommonStateService } from './common/service';
import { MasterDataStateService } from './master-data/service';
import { AppState } from './app.state';
import { MaterialStateService } from './material/service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';

export * from './common/state-models/menu-item';
export * from './common/state-models/paging';
export * from './common/service';

export * from './master-data/state-models/scania-unit';
export * from './master-data/state-models/unit-of-measurement-type';
export * from './master-data/state-models/unit-of-measurement';
export * from './master-data/state-models/market';
export * from './master-data/state-models/component-type';
export * from './master-data/service';

export * from './flow/state-models/component-individual';
export * from './flow/state-models/component-search-form';
export * from './flow/state-models/component-unit';
export * from './flow/service';

export * from './material/state-models/material';
export * from './material/state-models/material-price';
export * from './material/state-models/material-price-type';
export * from './material/service';

export * from './production-price-list/state-models/production-price-list';
export * from './production-price-list/state';
export * from './production-price-list/service';

export * from './product-individual/state-models/component-type-production-value';
export * from './product-individual/state-models/product-individual';
export * from './product-individual/state';
export * from './product-individual/service';

export * from './component-allocation/state-models/component-allocation';
export * from './component-allocation/state-models/allocation-summary';

export * from './administraion/delivery-route-allocation-unit/state-models/delivery-route-allocation-unit';
export * from './administraion/delivery-route-allocation-unit/state-models/scania-unit-allocation';
export * from './administraion/delivery-route-allocation-unit/state-models/scania-unit-component-type';
export * from './administraion/delivery-route-allocation-unit/state-models/product-class';
export * from './administraion/delivery-route-allocation-unit/state-models/scania-unit-consolidation';

export * from './administraion/delivery-route/state-models/delivery-route';

export * from './componentDetails/state-models/variant-class';
export * from './componentDetails/state-models/variant-family';
export * from './componentDetails/state-models/variant-option';

export * from './common/state-models/edge-result';

export * from './notifications/state-models/system-notifications';
export * from './notifications/service';

import { environment } from '../environments/environment';
import { ApiModule } from '../api';
import { OrchestratorStateService } from './orchestrator/service';
import { ComponentStateService } from './flow/service';
import { ComponentDetailsStateService } from './componentDetails/service';
import { ProductionPriceListStateService } from './production-price-list/service';
import { ComponentAllocationStateService } from './component-allocation/service';
import { FinalAssemblyAllocationStateService } from './final-assembly-allocation/service';
import { ConsolidationUnitAllocationStateService } from './consolidation-unit-allocation/service';
import { CPAReportStateService } from './cpa-report/service';
import { ProductIndividualStateService } from './product-individual/service';
import { ConsumingPlanningAreaMappingStateService } from './administraion/consuming-planning-area-mapping/service';
import { DeliveryRouteStateService } from './administraion/delivery-route/service';
import { DeliveryRouteAllocationUnitStateService } from './administraion/delivery-route-allocation-unit/service';
import { NotificationsService } from './notifications/service';
import { CurrencyAdjustmentStateService } from './administraion/currency-adjustment/service';
import { SimulationStateService } from './simulation/service';
import { ReplacementComponentListStateService } from './replacement-component-list/service';
import {  MissingSIMMaterialListStateService } from './missing-sim-material/service';
import { OverviewStateService } from './overview/service';
import { RemovedInvoicedateListStateService } from './removed-invoice-date/service';


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze] : [];

@Injectable()// TODO: Add Angular decorator.
@NgModule({
    imports: [
        ApiModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, { metaReducers}),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        })
    ],
    providers: [
        CommonStateService,
        MasterDataStateService,
        MaterialStateService,
        OrchestratorStateService,
        ComponentStateService,
        ComponentDetailsStateService,
        ProductionPriceListStateService,
        ComponentAllocationStateService,
        FinalAssemblyAllocationStateService,
        ConsolidationUnitAllocationStateService,
        CPAReportStateService,
        ProductIndividualStateService,
        ConsumingPlanningAreaMappingStateService,
        DeliveryRouteStateService,
        DeliveryRouteAllocationUnitStateService,
        NotificationsService,
        CurrencyAdjustmentStateService,
        NotificationsService,
        SimulationStateService,
        ReplacementComponentListStateService,
        MissingSIMMaterialListStateService,
        OverviewStateService,
        RemovedInvoicedateListStateService
    ]
})
export class StateModule implements OnDestroy {
    subscription = new Subscription();

    constructor(appStore: Store<AppState>) {
        this.subscription.add(
            appStore.subscribe(state => (<any>window).state = state)
        );
    }

    ngOnDestroy() { this.subscription.unsubscribe(); }
}
