import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { MenuComponent } from './menu/menu.component';
import { UserInfoModalComponent } from './user-info-modal/user-info-modal.component';
import { CalsiumListComponent } from '../calsiumlist/calsiumlist.component';
import { CalsiumListCellComponent } from '../calsiumlist/calsiumlist-cell/calsiumlist-cell.component';
import { CalsiumlistRowComponent } from '../calsiumlist/calsiumlist-row/calsiumlist-row.component';
import { CalsiumListSubListComponent } from '../calsiumlist/calsiumlist-subcomponent/calsiumlist-sublist.component';
import { NumberOnlyDirective } from '../shared/directives/calsium.number.directive';
import { CalsiumDateFormatterPipe } from '../shared/calsium-date.pipe';
import { CalsiumNumberFormatterPipe } from '../shared/calsium-number.pipe';
import { DynamicPipe } from '../shared/dynamic-pipe';
import { GlowElementOnFoucsDirective } from '../shared/directives/glowElelmentonFocusdirective';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalsiumlistSublistDirective } from '../calsiumlist/calsiumlist-subcomponent/calsiumlist-sublist.directive';
import { PaginationComponent } from './pagination/pagination.component';
import { SanitizeHtmlPipe } from '../shared/sanitize-html.pipe';
import { AllocationUnitSelectionComponent } from './allocation-unit-selection/allocation-unit-selection.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton } from '@angular/material/radio';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { SearchModule } from '../search';
import { ConfirmationAdvanceDialogComponent } from './confirmation-advance-dialog/confirmation-advance-dialog.component';
import { ConfirmationAdvanceDialogService } from './confirmation-advance-dialog/confirmation-advance-dialog.service';

@NgModule({
    imports: [
        AngularCommonModule,
        RouterModule,
        RouterModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        SearchModule,
        MatRadioButton
    ],
    declarations: [
        MenuComponent,
        UserInfoModalComponent,
        CalsiumlistSublistDirective,
        CalsiumListComponent,
        CalsiumListCellComponent,
        CalsiumlistRowComponent,
        CalsiumListSubListComponent,
        NumberOnlyDirective,
        CalsiumDateFormatterPipe,
        CalsiumNumberFormatterPipe,
        SanitizeHtmlPipe,
        DynamicPipe,
        GlowElementOnFoucsDirective,
        PaginationComponent,
        MonthPickerComponent,
        AllocationUnitSelectionComponent,
        ConfirmationDialogComponent,
        ConfirmationAdvanceDialogComponent
    ],
    exports: [
        MenuComponent,
        CalsiumListComponent,
        PaginationComponent,
        CalsiumNumberFormatterPipe,
        SanitizeHtmlPipe,
        MonthPickerComponent,
        AllocationUnitSelectionComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatSelectModule,
        NumberOnlyDirective
    ],
    providers: [
        ConfirmationDialogService,
        ConfirmationAdvanceDialogService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonModule { }
