import { RemovedInvoicedateAction, RemovedInvoicedateActionType } from "./action";
import { RemovedInvoicedateState } from "./state";


const initialState= require('./initial-data.json') as RemovedInvoicedateState;

export function RemovedInvoicedateReducer(
    state: RemovedInvoicedateState = initialState, 
    action: RemovedInvoicedateAction
): RemovedInvoicedateState {
    switch (action.type)
    {
            case RemovedInvoicedateActionType.LoadRemovedInvoicedateListAction:
                return {
                    ...state,
                    removedInvoicedatesList: action.payload
                };                        
            case RemovedInvoicedateActionType.ChangeRemovedInvoicedateListFilterExpressionAction:
                return {
                    ...state,
                    removedInvoicedatesFilterExpression: action.payload
                };
            case RemovedInvoicedateActionType.ChangeRemovedInvoicedateListSortExpressionAction:
                return {
                    ...state,
                    removedInvoicedatesSortExpression: action.payload
                };
            case RemovedInvoicedateActionType.ChangeRemovedInvoicedateListPageAction:
                return {
                    ...state,
                    removedInvoicedatesList: null,
                    removedInvoicedatesTotalCount: 0,
                    removedInvoicedatesPaging: { ...state.removedInvoicedatesPaging, page: action.payload },
                };
            case RemovedInvoicedateActionType.ChangeRemovedInvoicedateListTotalCountAction:
                return {
                    ...state,
                    removedInvoicedatesTotalCount: action.payload.totalCount
                };            
            default:
                return state;
    }    
}

