import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonStateService } from 'src/state';
import { ConfirmationAdvanceDialogComponent } from './confirmation-advance-dialog.component';
import { RemoveInvoiceDateForProductIndividual } from 'src/state/removed-invoice-date/state-models/removed-invoicedate-data';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationAdvanceDialogService {
    resources: any = this.commonService.getResources();

    constructor(public dialog: MatDialog,
        private commonService: CommonStateService) { }

    show(data: RemoveInvoiceDateForProductIndividual, title?: string, positiveButtonText?: string, negativeButtonText?: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const dialogRef = this.dialog.open(ConfirmationAdvanceDialogComponent, {
                width: '400px',
                maxWidth: '50vw',
                data: {
                    body: data,
                    title: title || this.resources.Confirm,
                    positiveButtonText: positiveButtonText || this.resources.OK,
                    negativeButtonText: negativeButtonText || this.resources.Cancel
                }
            });
            dialogRef.afterClosed().subscribe(result => resolve(result));
        });
    }
}

