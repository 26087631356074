<h2 mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content>
    <div class="d-flex flex-column">
        <p>ChassisNumber: {{data.body.chassisNumber}}</p>
        <p>SerialNumber: {{data.body.serialNumber}}</p>
        <p>ProductIndividualNumber: {{data.body.productIndividualNumber}}</p>
        <p>InvoiceDate:</p>
        <div *ngIf="data.body.invoiceDate">
            <div *ngFor="let date of data.body.invoiceDate">
                <mat-checkbox name="optcheckbox" (change)="checkedDateChange($event,date)" [checked]="date.detailsId === checkedDate.detailsId">{{date.invoiceDate | date:'shortDate'}}</mat-checkbox>
            </div>
        </div>
        <p>Country: {{data.body.country}}</p>
        <p>User: {{data.body.updatedBy}}</p>
        <div class="form-group w-100">
            <label>Reason Of Removal</label>
            <div class="input-group">
                <input [(ngModel)]="reasonOfRemoval" (input)="validationPatternChange($event)" class="form-control" placeholder="Enter Reason" required/>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button class="btn btn-primary" type="button" [disabled]="isOkDisabled()" [mat-dialog-close]="true" cdkFocusInitial (click)="save()">{{data.positiveButtonText}}</button>
    <button class="btn btn-secondary" type="button" [mat-dialog-close]="false" (click)="closeModel()">{{data.negativeButtonText}}</button>
</div>
