<div class="mr-5">
  <div class="wrapper">
    <div class="component-list">
      <div class="ul-container">
        <ul class="nav nav-tabs border-0" role="tablist">
          <div class="row">
            <div class="d-flex ml-5 col-sm-3">
              <cal-month-picker [(value)]="selectedPeriod" (valueChanged)="onPeriodSelected($event)">
              </cal-month-picker>
            </div>
          </div>
          <div>
            <div class="col-sm-2 ml-5 mt-3">
              <button class="btn btn-primary form-control inline" style="padding-left: 2.5rem; padding-right: 7rem;"
                type="submit" (click)="onSearchClick()">
                {{resources.Search}}
              </button>
            </div>
          </div>
        </ul>
      </div>
      <div class="d-flex flex-column mr-5">
        <div class="d-flex"><span class="icon mr-5" style="background-color: green;"></span>
          <h4 class="text-nowrap heading-format">Completed</h4>
        </div>
        <div class="d-flex"><span class="icon mr-5" style="background-color: yellow;"></span>
          <h4 class="text-nowrap heading-format">In Progress</h4>
        </div>
        <div class="d-flex"><span class="icon mr-5" style="background-color: red;"></span>
          <h4 class="text-nowrap heading-format">Not Started</h4>
        </div>
      </div>
    </div>
    <div class="ml-5 mb-2">
      <span class="max-limit__text-font "><span class="max-limit__text-style">*
        </span>{{resources.CheckboxUncheckingConditionInformation}}</span>
    </div>
  </div>


  <div class="d-flex flex-column">
    <div class="row">
      <div class="col mx-5">
        <div class="property-title" style="text-align: center;">
          <h4 class="heading-format ">SEU Component</h4>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Scania Unit</th>
                <th>Status</th>
                <th>Controller Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cm of seuComponentList">
                <th>{{cm.name}}</th>
                <td>
                  <span class="icon mr-5" [ngClass]="getIndicatorColour(cm)"></span>
                </td>
                <th>
                  <div class="d-flex justify-content-center">
                    <mat-checkbox name="optcheckbox" (change)="onCheckboxChange($event, cm)"
                      [checked]="cm.isCompleted"></mat-checkbox>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col mx-5">
        <div class="property-title" style="text-align: center;">
          <h4 class="heading-format ">SLA Component</h4>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Scania Unit</th>
                <th>Status</th>
                <th>Controller Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cm of slaComponentList">
                <th>{{cm.name}}</th>
                <td>
                  <span class="icon mr-5" [ngClass]="getIndicatorColour(cm)"></span>
                </td>
                <th>
                  <div class="d-flex justify-content-center">
                    <mat-checkbox name="optcheckbox" (change)="onCheckboxChange($event, cm)"
                      [checked]="cm.isCompleted"></mat-checkbox>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col mx-5">
        <div class="property-title" style="text-align: center;">
          <h4 class="heading-format">Final Assembly</h4>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Scania Unit</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cm of finalAssemblyUnit">
                <th>{{cm.name}}</th>
                <td>
                  <span class="icon mr-5" [ngClass]="getIndicatorColour(cm, true)"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col mx-5">
        <div class="property-title" style="text-align: center;">
          <h4 class="heading-format">Consolidation</h4>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Scania Unit</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cm of consolidationUnit">
                <th>{{cm.name}}</th>
                <td>
                  <span class="icon mr-5" [ngClass]="getIndicatorColour(cm, true)"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div [ngClass]="{loading: isLoading | async}" style="height: 100px;"></div>
  </div>
</div>