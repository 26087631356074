import { commonReducer } from './common/reducer';
import { materialReducer } from './material/reducer';
import { OrchestratorReducer } from './orchestrator/reducer';
import { ComponentReducer } from './flow/reducer';
import { ComponentDetailsReducer } from './componentDetails/reducer';
import { MasterDataReducer } from './master-data/reducer';
//import { apolloReducer } from 'apollo-angular-cache-ngrx';
import { ProductionPriceListReducer } from './production-price-list/reducer';
import { ComponentAllocationReducer } from './component-allocation/reducer';
import { FinalAssemblyAllocationReducer } from './final-assembly-allocation/reducer';
import { ConsolidationUnitAllocationReducer } from './consolidation-unit-allocation/reducer';
import { CPAReportReducer } from './cpa-report/reducer';
import { ProductIndividualReducer } from './product-individual/reducer';
import { AdminReducer } from './administraion/reducer';
import { NotificationsReducer } from './notifications/reducer';
import { SimulationReducer } from './simulation/reducer';
import { ReplacementComponentListReducer } from './replacement-component-list/reducer';
import { MissingSIMMaterialListReducer } from './missing-sim-material/reducer';
import { RemovedInvoicedateReducer } from './removed-invoice-date/reducer';

export const reducers = {
    //apollo: apolloReducer,
    common: commonReducer,
    material: materialReducer,
    orchestrator: OrchestratorReducer,
    component: ComponentReducer,
    componentDetails: ComponentDetailsReducer,
    masterData: MasterDataReducer,
    productionPriceList: ProductionPriceListReducer,
    componentAllocation: ComponentAllocationReducer,
    finalAssemblyAllocation: FinalAssemblyAllocationReducer,
    consolidationUnitAllocation: ConsolidationUnitAllocationReducer,
    cpaReport: CPAReportReducer,
    productIndividual: ProductIndividualReducer,
    admin: AdminReducer,
    notifications: NotificationsReducer,
    simulations: SimulationReducer,
    replacementComponentLists: ReplacementComponentListReducer,
    missingSIMMaterials: MissingSIMMaterialListReducer,
    removedInvoicedates: RemovedInvoicedateReducer
};
