import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, debounceTime, Observable, Subscription, switchMap } from 'rxjs';
import { CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { CalsiumDateFormatterPipe } from 'src/app/shared/calsium-date.pipe';
import { CommonStateService, Paging } from 'src/state';
import { RemovedInvoicedateListStateService } from 'src/state/removed-invoice-date/service';
import { RemoveInvoiceDateForProductIndividual } from 'src/state/removed-invoice-date/state-models/removed-invoicedate-data';

@Component({
  selector: 'cal-removed-invoicedate-list',
  templateUrl: './removed-invoicedate-list.component.html',
  styleUrl: './removed-invoicedate-list.component.css'
})
export class RemovedInvoicedateListComponent implements OnInit, OnDestroy{

  resources: any = this.commonService.getResources();
  listConfig: CalsiumListConfig;
  removedInvoiceDatesList: Observable<RemoveInvoiceDateForProductIndividual[]>;
  isLoading: Observable<boolean>;
  paging: Observable<Paging>;
  totalCount: Observable<number>;
  subscriptions = new Subscription();
  sortingExpression: Observable<string>;
  filterExpression: Observable<string>;

  constructor(
    private removedInvoicedateListService: RemovedInvoicedateListStateService,
    private commonService: CommonStateService,
  ){}

  ngOnInit() {
    this.isLoading = this.commonService.getLoading();
    this.sortingExpression = this.removedInvoicedateListService.getSortingExpression();
    this.filterExpression = this.removedInvoicedateListService.getFilterExpression();
    this.totalCount = this.removedInvoicedateListService.getTotalCount();
    this.paging = this.removedInvoicedateListService.getPaging();
    this.removedInvoiceDatesList = this.removedInvoicedateListService.getRemovedInvoiceDateList();

    this.subscriptions.add(
      combineLatest(this.paging, this.sortingExpression, this.filterExpression).pipe(
        debounceTime(0),
        switchMap(([paging, sortingExpression, filterExpression]) => {
          return this.removedInvoicedateListService.loadRemovedInvoicedatesList(
            paging,
            sortingExpression,
            filterExpression
          );
        })
      ).subscribe()
    );
    this.buildList();
  }

  buildList() {
    this.listConfig = new CalsiumListConfig({
      ShowListCaption: true,
      ShowExportToExcel: false,
      ListCaption: this.resources.RemovedInvoicedatesTitle,
      ShowFilterRow: true,
      ShowSumRow: false,
      ParentComponent: this
    });

    let column;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'chassisNumber', this.resources.ChassisNumber);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'serialNumber', this.resources.SerialNumber);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'productIndividualNumber', this.resources.ProductIndividual);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'invoiceDate', this.resources.InvoiceDate);
    column.AllowFilter = true;
    column.Sortable = true;
    column.DisplayPipe = new CalsiumDateFormatterPipe();

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'country', this.resources.CountryCode);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'updatedBy', this.resources.UserId);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'reasonOfRemoval', this.resources.ReasonOfRemoval);
    column.AllowFilter = true;
    column.Sortable = true;
  }

  ngOnDestroy() { this.subscriptions.unsubscribe(); }

  onPaging(page: number) {
    this.removedInvoicedateListService.goToPage(page);
  }

  onSort(sortExpression: string) {
    this.removedInvoicedateListService.sort(sortExpression);
  }

  onClearFilter() {
    this.removedInvoicedateListService.clearFilterAndSorting();
  }

  onFilter(filterExpression: string) {
    this.removedInvoicedateListService.filter(filterExpression);
  }

}
