import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../authentication';
import { CommonModule } from '../common';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { CPAReportComponent } from './cpa/cpa-report.component';
import { ReplacementComponentsComponent } from './replacement-components/replacement-components.component';
import { MissingSIMMaterialsComponent } from './missing-sim-materials/missing-sim-materials.component';
import { RemovedInvoicedateListComponent } from './removed-invoicedate-list/removed-invoicedate-list.component';

export const reportsRoutes: Routes = [
    { path: '',
    redirectTo: 'cpa',
    pathMatch: 'full' },
    {
        path: 'reports',
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'cpa',
                component: CPAReportComponent
            },
            {
                path: 'replacementComponents',
                component: ReplacementComponentsComponent
            },
            {
                path:'missingSIMMaterials',
                component:MissingSIMMaterialsComponent
            },
            {
                path: 'removedInvoicedate',
                component: RemovedInvoicedateListComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        AngularCommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        CPAReportComponent,
        ReplacementComponentsComponent,
        MissingSIMMaterialsComponent,
        RemovedInvoicedateListComponent
    ]
})
export class ReportsModule { }
