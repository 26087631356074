import { Action } from "@ngrx/store";
import * as _ from 'lodash';
import { RemoveInvoiceDateForProductIndividual } from "./state-models/removed-invoicedate-data";


export enum RemovedInvoicedateActionType {
    LoadRemovedInvoicedateListAction='[ RemoveInvoiceDateForProductIndividual ] Load removed Invoicedate list',  
    ChangeRemovedInvoicedateListPageAction='[ RemoveInvoiceDateForProductIndividual ] Change removed Invoicedate list',
    ChangeRemovedInvoicedateListSortExpressionAction='[ RemoveInvoiceDateForProductIndividual ] Change Sort Expression',
    ChangeRemovedInvoicedateListFilterExpressionAction='[ RemoveInvoiceDateForProductIndividual ] Change Filter Expression',
    ChangeRemovedInvoicedateListTotalCountAction='[ RemoveInvoiceDateForProductIndividual ] Total Count Expression'
}

export class ChangeRemovedInvoicedateListSortExpressionAction implements Action {
    readonly type = RemovedInvoicedateActionType.ChangeRemovedInvoicedateListSortExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeRemovedInvoicedateListFilterExpressionAction implements Action {
    readonly type = RemovedInvoicedateActionType.ChangeRemovedInvoicedateListFilterExpressionAction;
    constructor(public payload: string) { }
}

export class ChangeRemovedInvoicedateListTotalCountAction implements Action {
    readonly type = RemovedInvoicedateActionType.ChangeRemovedInvoicedateListTotalCountAction;
    payload: { totalCount: number};
    constructor(totalCount: number) {
        this.payload = { totalCount};
    }
}

export class ChangeRemovedInvoicedateListPageAction implements Action {
    readonly type = RemovedInvoicedateActionType.ChangeRemovedInvoicedateListPageAction;
    constructor(public payload: number) { }
}

export class LoadRemovedInvoicedateListAction implements Action {
    readonly type = RemovedInvoicedateActionType.LoadRemovedInvoicedateListAction;
    constructor(public payload: RemoveInvoiceDateForProductIndividual[]) { }
}


export type RemovedInvoicedateAction =
    | LoadRemovedInvoicedateListAction   
    | ChangeRemovedInvoicedateListSortExpressionAction
    | ChangeRemovedInvoicedateListFilterExpressionAction
    | ChangeRemovedInvoicedateListTotalCountAction
    | ChangeRemovedInvoicedateListPageAction
    ;