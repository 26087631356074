import { Component } from '@angular/core';
import { Observable, distinctUntilChanged, forkJoin, map } from 'rxjs';
import { OverviewStateService } from 'src/state/overview/service';
import { UntypedFormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { CommonStateService } from 'src/state';
import { LoadingItem } from 'src/state/common/state-models/loading-item';
import { Md5 } from 'ts-md5/dist/md5';
import { AuthService } from 'src/app/authentication';
import { AllocationOverviewConfirmationCheck } from 'src/state/overview/state-models/overview-data';
import { ConfirmationDialogService } from 'src/app/common/confirmation-dialog/confirmation-dialog.service';
import toastConfig from 'src/app/shared/custom-toast-options';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'cal-allocation-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.css'],
    providers: []
})
export class OverviewComponent {

    seuComponentList: any[] = [];
    slaComponentList: any[] = [];
    componentUnit: any[] = [];
    finalAssemblyUnit: any[] = [];
    consolidationUnit: any[] = [];
    fromDateControl: UntypedFormControl;
    toDateControl: UntypedFormControl;
    resources: any = this.commonService.getResources();
    selectedPeriod: Moment;
    selectedScaniaUnitId: number = 0;
    isLoading: Observable<boolean>;
    profile$: Observable<any>;
    updatedBy: any;

    constructor(private stateService: OverviewStateService,
        private commonService: CommonStateService,
        private authService: AuthService,
        private confirmationDialog: ConfirmationDialogService,
        private toastr: ToastrService,) { }

    ngOnInit() {
        this.isLoading = this.commonService.getLoading();
        this.profile$ = this.authService.userInfo.pipe(distinctUntilChanged(), map(u => (u ? u.profile : {})));
        this.profile$.subscribe((user) => {
            this.updatedBy = user.sub
        })
    }

    onPeriodSelected(period: any) {
        this.selectedPeriod = period;
    }

    async onSearchClick() {
        const period = this.selectedPeriod || moment();
        const selectedScaniaUnitId = this.selectedScaniaUnitId;
        const fromDate = period.date(1).format('YYYY-MM-DD');
        const toDate = period.add(1, 'months').date(1).subtract(1, 'days').format('YYYY-MM-DD');
        const loadingItem = <LoadingItem>{
            key: Md5.hashStr(JSON.stringify(new Date().getTime()))
        };
        this.slaComponentList=[];
        this.seuComponentList=[];
        this.finalAssemblyUnit=[];
        this.consolidationUnit=[];
        this.commonService.startLoading(loadingItem);
        const componentUnit$ = this.stateService.GetByComponents(fromDate, toDate, selectedScaniaUnitId); 
        const finalAssemblyUnit$ = this.stateService.GetByFinalAssembly(fromDate, toDate, selectedScaniaUnitId); 
        const consolidationUnit$ = this.stateService.GetByConsolidation(fromDate, toDate, selectedScaniaUnitId);
        const overviewDataList$ = forkJoin({
            componentUnit$: componentUnit$,
            finalAssemblyUnit$: finalAssemblyUnit$,
            consolidationUnit$: consolidationUnit$
        })
        overviewDataList$.subscribe({
            next: (data) => {
                const seuScaniaUnitIds = [1, 2, 3, 7, 9, 33];
                this.componentUnit = data.componentUnit$;
                this.slaComponentList = this.componentUnit.filter((value) => {
                    return !seuScaniaUnitIds.includes(value.scaniaUnitId)
                });
                this.seuComponentList = this.componentUnit.filter((value) => {
                    return seuScaniaUnitIds.includes(value.scaniaUnitId)
                })
                this.finalAssemblyUnit = data.finalAssemblyUnit$;
                this.consolidationUnit = data.consolidationUnit$;
                this.commonService.finishLoading(loadingItem);
            },
            error: () => { this.commonService.finishLoading(loadingItem); }
        })
    }

    getIndicatorColour(overviewDataList, isCheckboxAvailable: boolean = false) {

        let colourStatus = "red-background";

        if (overviewDataList.produce === 0 && overviewDataList.allocatedCount > 0) {
            if(isCheckboxAvailable) {
                colourStatus =  "green-background";
            } else {
                colourStatus = overviewDataList.isCompleted ? "green-background" : "yellow-background";
            }
        }
        else if (overviewDataList.produce > 0 && overviewDataList.allocatedCount > 0) {
            colourStatus = "yellow-background";
        }

        return colourStatus;
    }

    async onCheckboxChange(event: any, scaniaUnit: any) {
        const isCompleted = event.checked;

        const payload: AllocationOverviewConfirmationCheck = {
            scaniaUnitId: scaniaUnit.scaniaUnitId,
            updatedBy: this.updatedBy,
            isCompleted
        };
        if (isCompleted) {
            const result = await this.confirmationDialog.show(this.resources.AllocationCheck);
            if (!result) {
                event.source.checked=false;
                return;
            }
        }
        const result: any = await this.stateService.SaveAllocationOverviewConfirmationCheck(payload);
        if(result?.data?.allocationOverviewConfirmationCheck?.saveAllocationOverviewConfirmationCheck?.isCompleted) {
            this.toastr.success(this.resources.AllocatedCheckedScaniaUnit, scaniaUnit.name, toastConfig);
        } else {
            this.toastr.success(this.resources.AllocatedCheckedScaniaUnitUnchecked, scaniaUnit.name, toastConfig);
        }
        this.onSearchClick();
    }
}